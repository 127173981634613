import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { get } from 'lodash';
import * as API from '@/api/projects.api';
import { User } from '@/types/users.types';

export interface ProjectsState {
  projects: API.Projects.ProjectListProject[];
}

@Module({ dynamic: true, store, name: 'projects' })
class Projects extends VuexModule {
  public projects: ProjectsState['projects'] = [];
  public filteredProjects: API.Projects.ConnectingProjectList[] = [];
  
  public isAdminMode = false;

  @Mutation
  public setProjects(projects: API.Projects.ProjectListProject[]): void {
    this.projects = projects || [];
  }

  @Mutation
  public setAdminMode(mode: boolean): void {
    this.isAdminMode = mode;
  }

  @Mutation
  public setFilteredProjects(projects: API.Projects.ConnectingProjectList[]): void {
    this.filteredProjects = projects || [];
  }

  @Mutation
  public setFilteredAllProjects(projects: API.Projects.ProjectListProject[]): void {
    this.projects = projects || [];
  }

  @Mutation
  public addUserToProjectId(data: { projectId: number, userInfo: User }): void {
    const project: API.Projects.ProjectListProject | undefined = this.projects.find((project: any) => {
      return project.projectId === data.projectId || undefined;
    });
    const projectUsers: User[] = get(project,'projectUsers',[]);
    projectUsers.push(data.userInfo);
  }

  @Mutation
  public removeUserFromProject(data: { projectId: number, userInfo: User }): void {
    const project: API.Projects.ProjectListProject | undefined = this.projects.find((project: any) => {
      return project.projectId === data.projectId || undefined;
    });
    const projectUsers: User[] = get(project,'projectUsers',[]);
    const index = projectUsers.findIndex((user: User) => user.mudId === data.userInfo.mudId);
    if(index !== -1) {
      projectUsers.splice(index, 1)
    }
  }

  @Action({ commit: 'setProjects' })
  public async getProjects(): Promise<API.Projects.ProjectListProject[]> {
    return (await API.getProjects()).data.projects;
  }

  @Action({ commit: 'setFilteredProjects' })
  public async getConnectingServiceProjects(data: {serviceId: number}) : Promise<API.Projects.ConnectingProjectList>{
    const projList = await API.getConnectingServiceProjects(data);
    return get(projList, 'data.projects',[]);
  }

  @Action({ commit: 'setFilteredAllProjects' })
  public async getAllProjects() : Promise<API.Projects.ProjectListProject>{
    const projList = await API.getAllProjects();
    return get(projList, 'data.projects',[]);
  }

  @Action({ commit: 'setAdminMode' })
  public async setAdminModeForProjects(mode: boolean) : Promise<boolean>{
    return mode;
  }

}

export const ProjectsModule = getModule(Projects);
