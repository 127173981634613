
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { ProjectsModule } from '@/store/modules/projects.module';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { ListingsModule as ListingCatalogModule } from '@/store/modules/listings.module';
import { RouteNames } from '@/constants';
import { createProject } from '@/api/projects.api';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { SelectOption, SelectValue } from '@/components/form/form.types';
import GAnalytics from '@/components/GAnalytics';
import ValidatedForm from '@/components/form/ValidatedForm.vue';

interface NewProjectForm {
  projectName: string;
  projectDescription: string;
  businessUnit: string;
  businessSubUnit?: string;
  requestedServiceId: number;
}

enum ProjectTypes {
  New = 'NEW',
  Existing = 'EXISTING',
}

@Component({
  components: {
    FullScreenForm,
    GButton,
    GSelect,
    GRadioGroup,
    GTextfield,
    GAnalytics,
    ValidatedForm,
  },
})
export default class ProjectConnectView extends Vue {
  private loading = true;
  public listingName: string = ListingCatalogModule.currentListing.listingName;
  private projectId: string | number = '';
  private ready = false;

  projectTypeOptions: SelectOption[] = [
    {
      label: 'Existing',
      value: ProjectTypes.Existing,
    },
    {
      label: 'New',
      value: ProjectTypes.New,
    },
  ];
  private projectType: SelectValue = this.projectTypeOptions[0].value;

  back() {
    this.$router.safeBack(this.listingPath);
  }

  get listingPath(): Location {
    return {
      name: RouteNames.ListingDetails,
      params: {
        listingId: this.$route.params.listingId,
      },
    };
  }

  get listingId() {
    return this.$route.params.listingId;
  }

  get snackbarError() {
    return this.isExistingProject
      ? 'Error: Could not connect service'
      : 'Error: could not create new project';
  }

  get projects() {
    // get only projects w/o cicd env
    return (
      ProjectsModule.projects
        // only keep projects without a CICD environment
        .filter(project => project.environments.findIndex(env => env.mnemonic === 'CICD') === -1)
        .map(project => {
          return {
            key: project.projectId,
            value: project.projectId,
            label: project.projectName,
          };
        })
    );
  }

  async getNewProjectDetails(data: NewProjectForm): Promise<number> {
    const {
      data: { projectId },
    } = await createProject(data);
    return projectId;
  }

  get selectedProjectName(): string | null {
    if (this.isExistingProject) {
      return (
        this.projects.find(projectOption => projectOption.value === this.projectId)?.label ?? null
      );
    }
    return null;
  }

  async save(): Promise<void> {
    this.$router.push({
      name: RouteNames.NewProjectForm,
      params: {
        type: 'cicd',
      },
      query: {
        tlid: this.listingId,
        pid: this.isExistingProject ? this.projectId + '' : null,
      },
    });
  }

  async created() {
    if (ListingCatalogModule.noCurrentListing) {
      // when user navigates directly to this page
      await Promise.all([
        // handle 404 for listing deets
        ListingCatalogModule.getListingDetails(+this.$route.params.listingId),
        // ListingCatalogModule.getListingEnvironments(+this.$route.params.listingId),
        ProjectsModule.getProjects(),
      ]);
    } else {
      // when linked here from service details page
      await Promise.all([
        // ListingCatalogModule.getListingEnvironments(+this.$route.params.listingId),
        ProjectsModule.getProjects(),
      ]);
    }

    // preselect the last viewed project
    if (ProjectDetailsModule.isInitialState) {
      const p = ProjectsModule.projects[0];
      if (p) {
        this.projectId = p.projectId;
      } else {
        this.projectTypeOptions.shift();
      }
    } else {
      this.projectId = ProjectDetailsModule.projectDetails.projectId;
    }
    this.loading = false;
    this.ready = true;
  }

  get isExistingProject() {
    //  handle cicd type projects only
    this.projectType =
      // eslint-disable-next-line max-len
      ProjectsModule.projects.length === 0 && !this.loading ? ProjectTypes.New : this.projectType;
    return this.projectType === ProjectTypes.Existing;
  }
}
